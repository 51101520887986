//***  OPEN MODAL YAPEE   ***//

const yapeModal = document.getElementById('modalYape');
yapeModal.style.display = 'none';

const openYapeModal = document.getElementById('btnYape');
openYapeModal.addEventListener('click', () => {
	yapeModal.style.display = 'flex';
});

const closeYapeModalBtn = document.getElementById(
	'closeYapeModal'
);
closeYapeModalBtn.addEventListener('click', () => {
	yapeModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === yapeModal) {
		yapeModal.style.display = 'none';
	}
});

//***  OPEN MODAL BCP   ***//

const bcpModal = document.getElementById('modalBCP');
bcpModal.style.display = 'none';

const openBcpModal = document.getElementById('btnBCP');
openBcpModal.addEventListener('click', () => {
	bcpModal.style.display = 'flex';
});

const closeBcpModalBtn = document.getElementById(
	'closeBcpModal'
);
closeBcpModalBtn.addEventListener('click', () => {
	bcpModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === bcpModal) {
		bcpModal.style.display = 'none';
	}
});

//***  OPEN MODAL BBVA   ***//


const bbvaModal = document.getElementById('modalBbva');
bbvaModal.style.display = 'none';

const openBvaModal = document.getElementById('btnBbva');
openBvaModal.addEventListener('click', () => {
	bbvaModal.style.display = 'flex';
});

const closeBbvaModalBtn = document.getElementById(
	'closeBvaModal'
);
closeBbvaModalBtn.addEventListener('click', () => {
	bbvaModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === bbvaModal) {
		bbvaModal.style.display = 'none';
	}
});

	  
///COPYSSS
const copyBtns = document.querySelectorAll('.copy-btn');

	copyBtns.forEach((copyBtn) => {
		copyBtn.addEventListener('click', () => {
			copyAccountNumber(copyBtn);
			setCopiedTooltip(copyBtn);
		});
	});

	function copyAccountNumber(copyBtn) {
		const accountNumberInputEl =
			copyBtn.parentElement.querySelector('input');
		navigator.clipboard.writeText(accountNumberInputEl.value);
	}

	function setCopiedTooltip(copyBtn) {
		const tooltipEl =
			copyBtn.parentElement.parentElement.querySelector('.copy-tooltip');
		tooltipEl.style.opacity = '1';
		setTimeout(() => {
			tooltipEl.style.opacity = '0';
		}, 3000);
	}